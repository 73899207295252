<template>
  <div class="w-100 h-100 overflow-auto">
    <b-container
      fluid="xl"
    >
      <div class="d-flex align-items-center mb-4 mt-4">
        <div class="h3">
          Контроль консультаций
        </div>

        <b-button
          v-if="checkFeatureAccess({ name: 'Контроль консультаций - Экспорт (кнопка)', url: '/consultations-control' })"
          variant="primary"
          :type="$const.PRIMARY_BUTTON"
          class="ml-auto"
          @click="openConsultationExportModal"
        >
          Экспорт
        </b-button>
      </div>

      <b-row>
        <b-col class="w-100">
          <b-input-group class="bg-white border border-primary rounded">
            <b-form-input
              v-model="personName"
              type="text"
              placeholder="Введите фио пациента"
              class="border-0"
              @keydown.enter="consultationsFetch"
            />
            <b-button
              variant="outline-primary"
              :type="$const.PRIMARY_BUTTON"
              class="rounded-0 border-0"
              @click="consultationsFetch"
            >
              <b-icon icon="search" />
            </b-button>
          </b-input-group>
        </b-col>
      </b-row>
      <div class="d-flex mb-4 mt-2 justify-content-between align-items-center">
        <div class="d-flex align-items-center">
          <b-form-input
            v-model="consultationId"
            type="text"
            debounce="500"
            placeholder="ID консультации"
            class="mr-2"
            size="sm"
            style="width: 175px"
            @keydown.enter="consultationsFetch"
          />

          <b-form-select
            v-model="type"
            size="sm"
            style="width: 200px"
            :disabled="consultationTypeDisabled"
            class="mr-2"
            @change="consultationsFetch"
          >
            <template #first>
              <b-form-select-option
                :value="null"
              >
                Тип консультации
              </b-form-select-option>
              <b-form-select-option
                :value="0"
              >
                ДМС
              </b-form-select-option>
            </template>
          </b-form-select>

          <b-form-select
            v-model="problemType"
            :options="problemTypes"
            text-field="title"
            value-field="id"
            size="sm"
            style="width: 225px"
            class="mr-2"
            @change="consultationsFetch"
          >
            <template
              #first
            >
              <b-form-select-option :value="null">
                Причина попадания
              </b-form-select-option>
            </template>
          </b-form-select>

          <b-form-checkbox
            v-model="isDeleted"
            size="sm"
            @change="consultationsFetch"
          >
            <span class="text-secondary">
              Искать в закрытых
            </span>
          </b-form-checkbox>

          <!-- <b-form-select
            v-model="status"
            value-field="index"
            size="sm"
            text-field="title"
            style="max-width: 250px"
            :options="statuses"
            @change="consultationsFetch"
          >
            <template #first>
              <b-form-select-option
                :value="null"
              >
                Выберите статус
              </b-form-select-option>
            </template>
          </b-form-select> -->
        </div>

        <b-form-select
          v-model="sortingDates"
          :options="SORTING_DATES_OPTIONS"
          text-field="title"
          value-field="id"
          size="sm"
          class="w-25"
        />
      </div>
      <div
        v-if="!totalCount && !isLoading"
        class="px-3 mb-4"
      >
        Ничего не найдено
      </div>

      <template v-if="isLoading">
        <preloader style="margin-top: 20px" />
      </template>

      <b-table
        v-if="totalCount && !isLoading"
        class="bg-white"
        bordered
        :fields="fields"
        :items="consultations"
        :current-page="currentPage"
        :per-page="perPage"
      >
        <template #cell(Дата)="row">
          <template v-if="row.item.slotDate">
            {{ formatDate(new Date(row.item.slotDate), 'dd.MM.yyyy') }}
          </template>
          <div v-else>
            -
          </div>
        </template>
        <template #cell(Время)="row">
          <template v-if="row.item.slotDate">
            {{ formatDate(new Date(row.item.slotDate), 'HH:mm') }}
          </template>
          <div v-else>
            -
          </div>
        </template>
        <template #cell(Просрочка)="row">
          <template v-if="row.item.slotDate">
            {{ overdueDate(row.item.slotDate) }}
          </template>
          <div v-else>
            -
          </div>
        </template>
        <template #cell(Причина-попадания)="row">
          <template v-if="row.item.problemInfo?.types?.length">
            <div
              v-for="reasonItem in row.item.problemInfo.types"
              :key="reasonItem"
            >
              {{ REASONS_GETTING_CONTROL_CONSULTATIONS[reasonItem]?.title }}
            </div>
          </template>
          <div v-else>
            -
          </div>
        </template>
        <template #cell(Действия)="row">
          <b-button
            v-if="type === 1"
            size="sm"
            :variant="controlStatus(row.item.problemInfo.status)"
            :type="$const.PRIMARY_BUTTON"
            class="text-nowrap"
            @click="openConsultationControlModal(row.item)"
          >
            {{ controlText(row.item.problemInfo.status) }}
          </b-button>
          <b-button
            v-else
            size="sm"
            :type="$const.PRIMARY_BUTTON"
            class="text-nowrap"
            @click="openConsultationControlModal(row.item)"
          >
            Обработать
          </b-button>
        </template>
      </b-table>

      <div class="d-flex align-items-center mb-4">
        <b-form-select
          v-model="perPage"
          size="sm"
          :options="[10, 25, 50]"
          style="width: 70px"
          class="mr-2"
        />
        <b-pagination
          v-model="currentPage"
          :total-rows="consultations.length"
          :per-page="perPage"
          size="sm"
          class="m-0"
        />
      </div>
    </b-container>
  </div>
</template>

<script>
import {
  format,
  // formatISO,
  // parseISO,
  // isEqual,
} from '@evd3v/date-fns';

import { mixinRoles } from '@/mixins';
import Preloader from '@/components/Preloader';

import { CONSULTATION_STATUSES, CONSULTATION_STATUSES_FOR_CLINIC_ADMIN, REASONS_GETTING_CONTROL_CONSULTATIONS } from '@/helpers/consts';
import { consultationControlText } from '@/helpers/utils';
import { mapGetters } from 'vuex';
import * as types from '@/store/types';

const SORTING_DATES_OPTIONS = [
  {
    id: 0,
    title: 'От старых к новым',
  },
  {
    id: 1,
    title: 'От новых к старым',
  },
];

// import AsyncList from '@/components/AsyncList';

export default {
  name: 'ConsultationsControl',
  page: {
    title: 'CRM Doctis - Контроль консультаций',
  },
  components: {
    Preloader,
  },
  mixins: [mixinRoles],
  data() {
    return {
      personName: null,
      type: null,
      problemType: null,
      isDeleted: false,
      status: null,
      consultationId: null,
      perPage: 10,
      currentPage: 1,
      sortingDates: 0,
      SORTING_DATES_OPTIONS,
      REASONS_GETTING_CONTROL_CONSULTATIONS,
      initialConsultations: [],
    };
  },
  computed: {
    ...mapGetters({
      consultationsProblemsTrigger: types.CONSULTATIONS_PROBLEMS_TRIGGER,
    }),
    seeTypesOtherOms() {
      return this.checkFeatureAccess({ name: 'Тип консультации - все кроме ОМС', url: '/consultations-control' });
    },
    consultationTypeDisabled() {
      return this.seeTypesOtherOms;
    },
    fields() {
      const fields = [
        {
          key: 'id',
          label: 'ID',
        },
        {
          key: 'patientPerson.fullName',
          label: 'ФИО',
        },
        {
          key: 'patientPerson.phoneNumber',
          label: 'Телефон',
        },
        {
          key: 'Дата',
          label: 'Дата консультации',
        },
        {
          key: 'Время',
          label: 'Время консультации',
        },
        {
          key: 'Причина-попадания',
          label: 'Причина попадания',
        },
        {
          key: 'Действия',
          label: '',
          class: 'td-button',
        },
      ];

      if (this.type === 1) {
        fields.splice(5, 0, {
          key: 'Просрочка',
          label: 'Просрочка',
        });
      }

      return fields;
    },
    statuses() {
      // eslint-disable-next-line max-len
      const statuses = this.checkFeatureAccess({ name: 'Фильтр. Статус консультации. Сменить статусы (скрыто: Консилиум, Ожидает оплаты, Таймаут оплаты, Забронировано, Отмена бронирования)', url: '/consultations-control' })
        ? CONSULTATION_STATUSES_FOR_CLINIC_ADMIN : CONSULTATION_STATUSES;
      return statuses.sort((a, b) => a.title.localeCompare(b.title));
    },
    consultations() {
      return this.$store.state.Consultation.control.consultations;
    },
    filteredConsultations() {
      return this.consultations;
    },
    problemTypes() {
      const problemTypes = Object.entries(this.REASONS_GETTING_CONTROL_CONSULTATIONS).reduce((acc, [key, value]) => {
        acc.push({
          id: key,
          title: value?.title,
        });

        return acc;
      }, []);

      return problemTypes;
    },
    totalCount() {
      return this.filteredConsultations.length;
    },
    isLoading: {
      get() {
        return this.$store.state.Consultation.control.isLoading;
      },
      set(newVal) {
        this.$store.commit(this.$types.CONSULTATIONS_CONTROL_LOADING_SET, newVal);
      },
    },
  },

  watch: {
    async consultationsProblemsTrigger() {
      await this.consultationsFetch();
    },
    sortingDates() {
      this.sortAndSaveConsultations();
    },
  },
  async created() {
    if (this.problemTypeOnlyOMS) {
      this.type = 1;
      this.problemType = 0;
    }
    if (this.seeTypesOtherOms) {
      this.type = 0;
    }
    await this.consultationsFetch();
  },
  beforeDestroy() {
    this.$store.commit(this.$types.CONSULTATIONS_CONTROL_SET, []);
    this.isLoading = false;
  },
  methods: {
    formatDate(value, variant) {
      return format(value, variant);
    },
    overdueDate(date) {
      const d1 = new Date(date);
      const d2 = new Date(d1.getTime() + 30 * 60000);
      return format(d2, 'HH:mm');
    },
    controlStatus(status) {
      switch (status) {
        case 1:
          return 'outline-primary';
        case 2:
          return 'primary';
        case 3:
          return 'danger';

        default:
          break;
      }
    },
    controlText(status) {
      return consultationControlText(status);
    },
    async consultationsFetch() {
      this.isLoading = true;
      try {
        const consultations = await this.$store.dispatch(this.$types.CONSULTATIONS_CONTROL_FETCH, {
          personName: this.personName,
          consultationId: this.consultationId,
          status: this.status,
          type: this.type,
          problemType: this.problemType,
          isDeleted: this.isDeleted,
        });
        this.initialConsultations = consultations;
        this.sortAndSaveConsultations();
      } catch (e) {
        console.warn(e);
      } finally {
        this.isLoading = false;
      }
    },
    sortAndSaveConsultations() {
      try {
        switch (this.sortingDates) {
          case 0:
            this.initialConsultations.sort((a, b) => new Date(a.slotDate) - new Date(b.slotDate));
            break;
          case 1:
            this.initialConsultations.sort((a, b) => new Date(b.slotDate) - new Date(a.slotDate));
            break;
          default:
            this.initialConsultations.sort((a, b) => new Date(a.slotDate) - new Date(b.slotDate));
        }
        this.$store.commit(this.$types.CONSULTATIONS_CONTROL_SET, this.initialConsultations);
      } catch (e) {
        console.error(e);
      }
    },
    async openConsultationControlModal(consultation) {
      if (consultation.problemInfo.status === 0) {
        await this.$store.dispatch(
          this.$types.CONSULATION_CONTROL_PROBLEM_RESOLVE,
          {
            consultationId: consultation.id,
            proccessingComment: consultation.problemInfo.doctorComment,
            proccessingPatientComment: consultation.problemInfo.patientComment,
            status: 1,
          },
        );
      }
      this.$store.commit(this.$types.OPEN_MODAL, {
        name: 'ConsultationControlModal',
        props: {
          consultation,
          afterFunc: () => this.consultationsFetch(),
        },
      });
    },
    openConsultationExportModal() {
      this.$store.commit(this.$types.OPEN_MODAL, { name: 'ConsultationControlExportModal' });
    },
  },
};
</script>

<style lang="scss" scoped>
  ::v-deep.td-button {
    width: 130px;
  }
</style>
